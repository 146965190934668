/*
 * Welcome
 */
console.log ('%c Zbadani.pl', 'background: #0848FF; color: #ffffff');  

/*
 * Replace all SVG images with inline SVG
 */
document.querySelectorAll('img.svg').forEach(function(img){
    var imgID = img.id;
    var imgClass = img.className;
    var imgURL = img.src;

    fetch(imgURL).then(function(response) {
        return response.text();
    }).then(function(text){

        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(text, "text/xml");

        // Get the SVG tag, ignore the rest
        var svg = xmlDoc.getElementsByTagName('svg')[0];

        // Add replaced image's ID to the new SVG
        if(typeof imgID !== 'undefined') {
            svg.setAttribute('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if(typeof imgClass !== 'undefined') {
            svg.setAttribute('class', imgClass+' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        svg.removeAttribute('xmlns:a');

        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
        if(!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
            svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
        }

        // Replace image with new SVG
        img.parentNode.replaceChild(svg, img);

    });
});


// Login and registration footer
if ($(window).height() < 1000) {
    $('.foot').addClass("static");
} else {
    $('.foot').removeClass("static");
}

// Opis: wyróżnione
$('a#btn-wyroznione').on('click', function() {
    if ($(this).hasClass('starred')) {
        $(this).removeClass('starred');
    } else {
        $(this).addClass('starred');
    }
});

// Inputs
(function( $, window, document, undefined )
{
    $( '.inputfile' ).each( function()
    {
        var $input   = $( this ),
            $label   = $input.next( 'label' ),
            labelVal = $label.html();

        $input.on( 'change', function( e )
        {
            var fileName = '';

            if( this.files && this.files.length > 1 )
                fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
            else if( e.target.value )
                fileName = e.target.value.split( '\\' ).pop();

            if( fileName )
                $label.find( 'span' ).html( fileName );
            else
                $label.html( labelVal );
        });

        // Firefox bug fix
        $input
        .on( 'focus', function(){ $input.addClass( 'has-focus' ); })
        .on( 'blur', function(){ $input.removeClass( 'has-focus' ); });
    });
})( jQuery, window, document );

// Hide / show desc
$('#szczegoly-zlecenia-pokaz-ukryj').on('click', function() {
    if ($(this).hasClass('aside-hidden')) {
        $('#card-opis').removeClass('col-md-12');
        $('#card-opis').addClass('col-md-7');
        $('#card-aside').show();
        $(this).removeClass('aside-hidden');
        $('#szczegoly-zlecenia-pokaz-ukryj').html('Ukryj szczegóły zlecenia');
    } else {
        $('#card-opis').addClass('col-md-12');
        $('#card-opis').removeClass('col-md-7');
        $('#card-aside').hide();
        $(this).addClass('aside-hidden');
        $('#szczegoly-zlecenia-pokaz-ukryj').html('Pokaż szczegóły zlecenia');
    }
});

// Modal Skierowanie
$(".skierowaniePreview").on("click", function(e) {
    e.preventDefault();
    $('#imagePreview').attr('src', $('#imageSource').attr('src')); 
    $('#skierowanieModal').modal('show');
    return false;
});

// Toast
var redirect;

$(".action-toast").on("click", function(e) {
    var toastType = $(this).attr('data-toast-type');
    var toastText = $(this).attr('data-toast-text');
    var toastHeader = $(this).attr('data-toast-header');
    var cancellable = $(this).attr('data-toast-cancellable');
    var spin = $(this).attr('data-toast-spin');
    var redirect = $(this).attr('data-toast-redirect');

    $('.toast h3').html(toastHeader);
    $('.toast h3').addClass(toastType);
    $('.toast p').html(toastText);

    if (spin == "no") {
        $('.toast svg').hide();
        $('.toast img').hide();
    } else {
        $('.toast svg').show();
        $('.toast img').show();
    }

    if (cancellable == "no") {
        $('.toast a').hide();
    } else {
        $('.toast a').show();
    }

    $('.toast').removeClass('success');
    $('.toast').toast('show');
    if (redirect == "yes") {
        var redirect = setTimeout(function(){ 
            window.location = "/evolution/dashboard/centrum-opisowe.html"; 
            $('.toast').toast('hide');
        }, 3000);
    } else {
        setTimeout(function(){ 
            $('.toast').toast('hide');
        }, 3000);
    }

        setTimeout(function(){ 
            $('.toast').addClass('success');
        }, 3000);

});

// Table Fixer
if ($('.table-fixed').length > 0 ) {
    $(".table-fixed").tableFixer({'left' : 1, 'head' : false});
};

// Date Range
$(document).ready(function() {

    if (window.moment) { 
        var start = moment().subtract(29, 'days');
        var end = moment();

        function cb(start, end) {
            $('#datepicker-range span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        }

        $('#datepicker-range').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: {
               'Today': [moment(), moment()],
               'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
               'Last 7 Days': [moment().subtract(6, 'days'), moment()],
               'Last 30 Days': [moment().subtract(29, 'days'), moment()],
               'This Month': [moment().startOf('month'), moment().endOf('month')],
               'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }, cb);

        $('.action__wlasny_zakres').daterangepicker({ customClass: 'daterangepicker--modal' });

        cb(start, end);
    }
});

//Table Clickable  
$(document).ready(function() {
    $('.table-clickable tr.tile').on('click', function() {
        if (!$(this).hasClass('tile-chosen')) {
            $('.table-clickable tr').removeClass('tile-chosen');
            $(this).addClass('tile-chosen');
        }
    })
})

// Resize user card to rectangle
$(document).ready(function() {
    if($('.user-card--sidebar').length > 0) {
        var uc = $('.user-card--sidebar');
        var ucWidth = uc.width(),
            ucHeight = uc.height();
        if (ucHeight > ucWidth) {
            uc.width(ucHeight);
        } else {
            uc.height(ucWidth);
        }

    }
});

// Resize Stat Card
function fixHeight(elem){
    var maxHeight = 0;
    $(elem).css('height','auto');
    $(elem).each(function(){
       if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
    });
    $(elem).height(maxHeight);
}

$(window).resize(function () {
    if($('.stat-card').length > 0) {
        //fixHeight('.stat-card');
    }
});
$(document).ready(function(e) {
    if($('.stat-card').length > 0) {
        //fixHeight('.stat-card');
    }
});

/*$(document).ready(function() {
    if($('.number-stat').length > 0) {
        var uc = $('.number-stat');
        var ucWidth = uc.width(),
            ucHeight = uc.height();
        if (ucHeight > ucWidth) {
            uc.width(ucHeight);
        } else {
            uc.height(ucWidth);
        }

    }
});
*/

// Add resolution warning 
// TODO: if body hasclass - because some of the screens will be mobile friendly
//function resolutionWarning(windowWidth) {
//    var appendContent = '<div class="resolution-warning"><img src="../../assets/images/logo-small-color.svg" alt="" style="width: 64px; margin-bottom: 25px;" /><h2>Ten ekran jest niedostępny<br />na tym urządzeniu.</h2><p>Dla bezpieczeństwa pacjentów i komfortu pracowników medycznych nie udostępniamy tego ekranu na ekranach mniejszych niż 1280 pikseli (w tym na urządzeniach mobilnych). <br /><br />Rekomendowana rozdzielczość pozioma pracy to min. 1920 pikseli.</p><a href="#" class="btn btn-primary btn-lg">&laquo; wróć</a></div>"';
//
//    if (windowWidth < 1280) {
//        if ($('.resolution-warning').length == 0) {
//            $('body').append(appendContent);
//            $('body').addClass('block');
//        }
//    } else {
//        $('.resolution-warning').remove();
//        $('body').removeClass('block');
//    }
//}
//
//$(document).ready(function() {
//    var vw = $(window).width();
//    resolutionWarning(vw);
//});
//
//$(window).resize(function() {
//    var vw = $(window).width();
//    resolutionWarning(vw);
//});

// Enable tooltips everywhere
$(document).ready(function() {
  $('[data-toggle="tooltip"]').tooltip()
})
